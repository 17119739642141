<template>
    <TransitionsBounce>
        <ElementsContainer v-if="modelValue" @mounted="onInfoMounted" class="fixed top-16 left-0 z-50 w-screen flex justify-center">
            <div class="rounded shadow border border-red p-4 md:p-6" v-bind:class="{
                'bg-orange-200' : color == 'orange',
                'bg-green-300' : color == 'green',
                'bg-primary text-white' : color == 'primary',
            }">
                <slot />
            </div>
        </ElementsContainer>
    </TransitionsBounce>
</template>
<script setup>
const props = defineProps({
    modelValue: { type: Boolean, default: false },
    timeout: { type: Number, default: 1450 },

    dontHide: { type: Boolean, default: false },
    color: { type: String, default: 'orange' },
})
const emit = defineEmits(['update:modelValue'])

const onInfoMounted = () => {
    if(props.modelValue && !props.dontHide){
        setTimeout(() => emit('update:modelValue', false), props.timeout)
    }
}
</script>