<template>
    <div>
        <slot />
    </div>
</template>
<script setup>
const emit = defineEmits(['mounted'])

onMounted(() => emit('mounted') )

</script>